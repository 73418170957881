import React from "react"

import Layout from "../components/Layout"
import styles from "./donate.module.less"

const DonatePage = ({ data }) => {
  const pageData = data.pageData.nodes[0]

  console.log(pageData.showDonateWidget)

  return (
    <Layout
      meta={{
        title: pageData.metaTitle,
        description: pageData.metaDescription.metaDescription,
        image: pageData.metaImage.localFile.publicURL,
      }}
    >
      <div
        className={`container ${pageData.showDonateWidget ? "" : "mt-5 pt-5"}`}
      >
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-9 col-xl-6 d-flex align-items-center">
            <div>
              <div className={"page_title mt-xl-0"}>
                <h1>{pageData.title}</h1>
              </div>
              {pageData.subtitle.subtitle.split("\n").map((text, idx) => (
                <p key={idx} className={styles.subtitle}>
                  {text}
                </p>
              ))}
            </div>
          </div>

          {pageData.showDonateWidget && (
            <div className="col-sm-12 col-xl-6">
              <div className={styles.donate_frame}>
                <embed
                  src="https://www.gofundme.com/mvc.php?route=widgets/mediawidget&fund=immigrants-of-canada-podcast&image=1&coinfo=1&preview=1"
                  type="text/html"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default DonatePage

export const query = graphql`
  query {
    pageData: allContentfulDonatePage {
      nodes {
        title
        subtitle {
          subtitle
        }
        showDonateWidget
        metaTitle
        metaDescription {
          metaDescription
        }
        metaImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
